/* .meter-select-list div {
    padding-left: 5px;
    padding-right: 5px;
} */

.meter-select-list div p {
    margin: 0;
    padding: 0;
}

.meter-select-list div {
    padding: 0 10px;
}

.meter-select-list div.selected {
    background-color: rgb(0, 0, 0, 0.05);
}

.meter-select-list .ant-divider {
    margin: 0;
}

.page-content > h1:first-child {
    margin-top: 0;
}

.subLabel {
    font-size: 0.8em;
    color: #999;
    margin-top: -6px;
}

.ant-card {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2) !important;
}


.subLabel {
    font-size: 0.8em;
    color: #999;
    margin-top: -6px;
}

.search-results {
    padding-bottom: 1em;
}
.search-result-item {
    padding: 2px 10px;
    border-bottom: 1px solid #eee;
}
.search-result-item .title {
    font-weight: bold;
    padding-top: 2px;
}
.search-result-item p {
    margin: 2px;
    padding: 2px 2px 2px 2em;
}
.search-result-item:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

.meter-overview-info th {
    text-align: right;
    padding-right: 5px;
    vertical-align: top;
}

.meter-overview-info .address-line {
    display: block;
}

.token-buttons {
    text-align: left;
}
.token-buttons div {
    margin-bottom: 5px;
}

.sts-token {
    font-family: monospace;
    padding: 1px 10px;
    cursor: pointer;
}

.history-item-detail {
    padding: 5px 20px;
    background-color: #f0f0f0;
    border-radius: 5px;
    border: 1px solid #CCC;
    box-shadow: 5px 5px 5px #CCC;
}

.info-table > tbody > tr > th {
    width: 100px;
    text-align: right;
    padding-right: 5px;
}

table.bordered {
    border-collapse: collapse;
}
table.bordered th, table.bordered td {
    border: 1px solid #CCC;
    padding: 5px;
}

table.white th, table.white td {
    background-color: white;
}

table.full-width {
    width: 100%;
}

table.shadow {
    box-shadow: 5px 5px 5px #CCC;
}

table.data th {
    background-color: #DDD;
}

.quick-form .ant-form-item {
    margin-bottom: 0;
}

tr.critical {
    background-color: #FFBBBB;
}

tr.warning {
    background-color: #FFDBBB;
}

tr.info {
    background-color: #CCFFCC;
}

.ant-card .ant-card-head  {
    background-color: #c3e5dc;
}